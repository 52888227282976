import website from '../assets/website.png'
import instagram from '../assets/instagram.png'
import youtube from '../assets/images (3).jpeg'
import discord from '../assets/discord.jpg'
import twitter from '../assets/twitter.png'
import twitch from '../assets/twitch.jpg'
import gamepedia from '../assets/gamepedia.jpeg'
import mmoga from '../assets/mmoga.png'
import merch from '../assets/merch.png'

const items = [
{
    "title": "Instagram",
    "subtitle": "@teampurgens | Gameplays & mehr unserer Player",
    "image": instagram,
    "link": "https://www.instagram.com/teampurgens/"
},
{
    "title": "Merch",
    "subtitle": "ARMA.GG X PURGENS MERCH",
    "image": merch,
    "link": "https://arma.gg/collections/purgens"
},
{
    "title": "Twitter",
    "subtitle": "@teampurgens | Sei auf dem neusten Stand",
    "image": twitter,
    "link": "https://twitter.com/teampurgens"
},
{
    "title": "Twitch",
    "subtitle": "@teampurgens | Hier streamen wir Turniere",
    "image": twitch,
    "link": "https://www.twitch.tv/teampurgens"
},
{
    "title": "Team Purgens Discord",
    "subtitle": "Spielersuche, Promotion und mehr!",
    "image": discord,
    "link": "https://discord.gg/PbKmvFw"
},
{
    "title": "Purgens Valorant Discord",
    "subtitle": "Spielersuche, Promotion und mehr!",
    "image": discord,
    "link": "https://discord.gg/M7tK2F6wUr"
},
{
    "title": "Gamepedia",
    "subtitle": "Wir sind seit kurzem auch auf Gamepedia",
    "image": gamepedia,
    "link": "https://fortnite-esports.fandom.com/wiki/Team_Purgens"
},
{
"title": "MMOGA",
"subtitle": "unser Partner MMOGA",
"image": mmoga,
"link": "https://www.mmoga.de/?ref=54101"
},
{
    "title": "YouTube",
    "subtitle": "@Team Purgens | Offizieller YouTube-Kanal",
    "image": youtube,
    "link": "https://www.youtube.com/channel/UCFIw6SEgZzJXqG6eKoLY7ww/videos"
}]

export default items
