import React from 'react'

import '../css/skeleton.css'
import '../css/normalize.css'
import '../css/components.css'

function Footer() {
    return (
        <div className = "Footer container">
            <p>Team Purgens | Social Medias</p>
        </div>
    )
}

export default Footer
